:root {
  --tribu-color-primary: #6cca98;
  --tribu-color-secondary: #7473c0;
  --tribu-color-tertiary: #ffc629;
  --tribu-color-dark: #000000;
  --tribu-color-gray: #828181;
  --tribu-color-danger: #cc2b2b;
  --tribu-color-default: #f1f1f1;
  --tribu-color-light: #f1f1f1;
  --tribu-color-info: #42a7c6;

  // Font family
  --tui-heading-font: Montserrat, Arial, Helvetica, sans-serif;
  --tui-text-font: Montserrat, Arial, Helvetica, sans-serif;
}

/********** OVERRIDES **********/
// TODO - Move to a separate file
.ant-image-preview-img-wrapper {
  //center image with position absolute
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  right: auto;
  bottom: auto;
}

.ant-descriptions-item-label {
  font-weight: bold !important;
}

.ant-upload {
  border-radius: theme('borderRadius.xl') !important;
}

.ant-btn:hover,
.ant-btn:focus {
  color: #fff;
  border-color: #fff;
}

tui-push {
  .t-link {
    button {
      color: rgba(0, 0, 0, 0.85) !important;
    }
  }
}

tui-dialog {
  > div.t-content {
    padding: 0 !important;
  }
}

tui-dialog[data-size='m'] {
  max-width: 40rem !important;
}

app-videocall {
  .t-item {
    padding: 0 !important;
  }
}

[tuiWrapper][data-appearance='textfield'] {
  box-shadow: none !important;
}

.ant-steps-item-icon {
  margin: 0 !important;
  width: 40px !important;
  height: 40px !important;
  line-height: 40px !important;
}
.ant-steps-item-title {
  padding-right: 0 !important;
}

.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  padding-left: 0 !important;
}
.ant-steps-item-wait .ant-steps-item-icon {
  border-color: var(--tribu-color-primary) !important;
  color: var(--tribu-color-primary) !important;
  & > .ant-steps-icon {
    color: var(--tribu-color-primary) !important;
  }
}
.ant-steps-item-active .ant-steps-item-icon {
  border-color: theme('colors.primary') !important;
  background: theme('colors.primary') !important;

  color: #fff !important;
}

tui-radio-block.danger {
  [tuiWrapper][data-appearance='primary'] {
    background: theme('colors.red.500') !important;
  }
}

/********** SPECIFIC TARGET STYLES **********/

tui-prompt {
  .t-buttons {
    [tuiWrapper][data-appearance='flat'] {
      color: #000 !important;
      &:hover {
        color: #000 !important;
        background: var(--tribu-color-default);
      }
    }
  }
}

#app-body
  > app-root
  > tui-root
  > tui-dropdown-host
  > tui-dialog-host
  > section
  > tui-dialog
  > header {
  background: #fff !important;
}

tui-dialog[data-size='page']:has(app-calendar-embed) {
  background: none !important;
  .t-content {
    background: none !important;
  }
}

app-create-publication,
app-form-bdt,
nz-steps {
  .ant-steps-item-icon {
    border-color: theme('colors.primary') !important;
  }
  .anticon {
    vertical-align: baseline !important;
    color: theme('colors.primary') !important;
  }
}

app-form-bdt {
  nz-carousel {
    .slick-list,
    .slick-track,
    .slick-slide {
      height: fit-content !important;
    }
  }
}

.ant-steps-item-finish .ant-steps-item-icon {
  background-color: theme('colors.primary') !important;
  border-color: theme('colors.primary') !important;

  & > .ant-steps-icon > .anticon {
    color: #fff !important;
  }
}

#year-btn {
  color: var(--tribu-color-secondary) !important;
}

tui-accordion-item.accordion-default {
  .t-wrapper {
    > {
      .t-header,
      .t-content {
        background-color: var(--tribu-color-default) !important;
        background: var(--tribu-color-default) !important;
      }
    }
  }

  .t-wrapper:after {
    border: none !important;
  }
  .t-header {
    border-bottom: none;
    // &.t-header_open {
    //   border-bottom: 1px solid var(--tui-base-04);
    // }
  }
}

.ant-statistic-title {
  margin-bottom: 0 !important;
}

tui-accordion-item.bg-default {
  .t-wrapper {
    background: var(--tribu-color-default) !important;
  }
}

tui-dropdown {
  border-radius: 10px !important;
  border: none !important;
}

[tuiWrapper][data-appearance='primary'] {
  background: var(--tribu-color-secondary);
}

[tuiWrapper][data-appearance='secondary'] {
  background: #333333a3;
}

[tuiWrapper][data-appearance='secondary']:hover:not(._no-hover),
[tuiWrapper][data-appearance='secondary'][data-state='hover'] {
  background: #333333a3;
}

[tuiWrapper][data-appearance='primary']:hover:not(._no-hover),
[tuiWrapper][data-appearance='primary'][data-state='hover'] {
  background: var(--tribu-color-secondary);
}

.ant-steps-item-title::after {
  background-color: theme('colors.default') !important;
  top: 20px !important;
}

tui-calendar > tui-primitive-year-month-pagination > tui-primitive-spin-button > button {
  background-color: var(--tribu-color-secondary) !important;
  opacity: 0.7 !important;

  &:hover {
    background-color: var(--tribu-color-secondary) !important;
    opacity: 1 !important;
  }
}
// shepperd css
// .shepherd-enabled {
// }
.shepherd-modal-overlay-container {
  opacity: 0.65 !important;
}
.shepherd-text {
  padding: 0 !important;
  line-height: normal !important;
  font-size: inherit !important;
}

.shepherd-cancel-icon::before {
  content: '';
  background-image: url('/assets/images/tutorial/close.png');
  background-size: 20px 20px; /* tamaño de la imagen */
  width: 20px; /* ancho del elemento */
  height: 20px; /* alto del elemento */
  display: block; /* display block para que se muestre la imagen */
}

.shepherd-cancel-icon span {
  display: none; /* oculta el contenido original */
}

.shepherd-header {
  align-items: unset;
  z-index: 10;
  border-top-left-radius: unset;
  border-top-right-radius: unset;
  display: unset;
  justify-content: unset;
  line-height: normal;
  padding: unset;
  position: absolute;
  top: 16px;
  right: 16px;
}

.shepherd-element[role='dialog'] {
  @apply max-w-[35rem] overflow-hidden rounded-2xl bg-dark-900 pb-4 shadow-none;
}
tui-checkbox-labeled.tui-checkbox-labeled-center > label {
  @apply flex items-center;
}

image-cropper > div {
  width: fit-content !important;
}

tui-pagination > div > button {
  background: theme('backgroundColor.stone.300') !important;
}

// QuillJS
quill-editor {
  width: 100%;
  font-family: var(--tui-heading-font);

  &[disabled] {
    opacity: 0.5;
    pointer-events: none;
  }

  .ql-toolbar {
    @apply rounded-t-lg;
  }

  .ql-container {
    @apply rounded-b-lg;

    .ql-editor {
      @apply max-h-[490px] min-h-36;
    }
    .ql-blank::before {
      font-style: normal !important;
    }
  }
}

quill-view > div > div {
  padding: 0 !important;
}
