.font-forest {
  font-family: 'Forest Line', Arial, Helvetica, sans-serif !important;
}

/*==========  Colors  ==========*/
$primary: var(--tribu-color-primary);
$secondary: var(--tribu-color-secondary);
$tertiary: var(--tribu-color-tertiary);
$dark: var(--tribu-color-dark);
$default: var(--tribu-color-default);
$gray: var(--tribu-color-gray);
$danger: var(--tribu-color-danger);
$light: #ffff;

$btn-colors-config: ('primary', $primary, $light), ('secondary', $secondary, $light),
  ('tertiary', $tertiary, $light), ('dark', $dark, $light), ('default', $default, $gray),
  ('light', $light, $gray), ('gray', $gray, $light), ('danger', $danger, $light);

/*==========  Buttons  ==========*/

@mixin props-btn-tribuu {
  // display: inline-block;
  // cursor: pointer;
  // appearance: none;
  // border-radius: theme('borderRadius.sm');
  // text-align: center;
  // padding: 0.75rem 1rem;
  @apply inline-block cursor-pointer appearance-none rounded-sm px-6 py-3 text-center font-semibold transition-all duration-200 ease-in-out;
  border-radius: theme('borderRadius.sm');
  box-shadow: unset;
  font-style: normal;
  font-family: Montserrat, Arial, Helvetica, sans-serif;

  &:disabled {
    opacity: 0.5 !important;
    pointer-events: none;
  }
  &:active {
    transform: scale(0.95);
  }
  // &:hover {
  //   box-shadow: theme('boxShadow.DEFAULT');
  // }
}

@mixin btn-tribuu {
  @include props-btn-tribuu;
  font-size: theme('fontSize.base');
  min-width: theme('width.40');
  @apply text-base;
}

@mixin btn-small-tribuu {
  @include props-btn-tribuu;
  @apply text-sm;
  min-width: auto;
  min-height: auto;
  height: fit-content;
  width: fit-content;
}

@each $name, $color, $text-color in $btn-colors-config {
  .btn-tribu-#{$name} {
    @include btn-tribuu;
    background-color: $color !important;
    color: $text-color !important;
    &:hover {
      background-color: $color !important;
    }
  }

  .btn-tribu-#{$name}-sm {
    @include btn-small-tribuu;
    background-color: $color !important;
    color: $text-color !important;
    &:hover {
      background-color: $color !important;
    }
  }
}

/*==========  placeholder colors  ==========*/
@each $name, $color in $btn-colors-config {
  .placeholder-#{$name} {
    &::placeholder {
      color: $color !important;
    }
  }
}

// .placeholder-tertiary::placeholder {
//   color: var(--tribu-color-tertiary) !important;
// }

@media (max-width: 768px) {
  @each $name, $color, $text-color in $btn-colors-config {
    .btn-tribu-#{$name} {
      @include btn-small-tribuu;
      background-color: $color !important;
      color: $text-color !important;
      &:hover {
        background-color: $color !important;
      }
    }
  }
}

/*==========  Tailwind  ==========*/
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@layer base {
  a {
    @apply no-underline transition-colors duration-200 ease-in-out hover:underline;
  }
  html,
  * {
    font-family: Montserrat, Arial, Helvetica, sans-serif !important;
  }
}

.form-publication .swiper-wrapper {
  height: 100% !important;
}

.swiper-button-prev:after {
  content: url(https://storage.googleapis.com/tribuu-bucket/Assets/icons/left.svg) !important;
}
.swiper-button-next:after {
  content: url(https://storage.googleapis.com/tribuu-bucket/Assets/icons/right.svg) !important;
}
.swiper-button-next {
  right: 0 !important;
}
.swiper-button-prev {
  left: 0 !important;
}

.swiper-pagination-bullet-active {
  background: #000 !important;
}

.swiper-wrapper {
  height: 100% !important;
}

/*==========  Utils  ==========*/
.lh-titles {
  line-height: 100%;
}

.text-normal {
  font-style: normal;
}

.controls .t-icon {
  color: $light !important;
}

/**
 * ----------------------------------------
 * animations
 * ----------------------------------------
 */
@-webkit-keyframes fade-in-left {
  0% {
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes fade-in-left {
  0% {
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@-webkit-keyframes fade-in-right {
  0% {
    -webkit-transform: translateX(150px);
    transform: translateX(150px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes fade-in-right {
  0% {
    -webkit-transform: translateX(150px);
    transform: translateX(150px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes fade-in-up {
  0% {
    -webkit-transform: translateY(150px);
    transform: translateY(150px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(-150px);
    transform: translateY(-150px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

.reveal.active.fade-in-left {
  -webkit-animation: fade-in-left 1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-left 1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.reveal.active.fade-in-right {
  -webkit-animation: fade-in-right 1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-right 1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
.animate-fade-in-left {
  -webkit-animation: fade-in-right 1s linear both;
  animation: fade-in-right 1s linear both;
}
.animate-fade-in-bottom-delay-1s {
  -webkit-animation: fade-in-bottom;
  animation: fade-in-bottom;
}

.reveal {
  position: relative;
  opacity: 0;
  // transition: 1s all ease;
}

.fade-in-up.reveal.active {
  -webkit-animation: fade-in-up 1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-up 1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

/**
 * ----------------------------------------
 * animation scale-in-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

.scale-in-center {
  -webkit-animation: scale-in-center 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-5-15 9:44:40
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in
 * todo - move to a separate file animations.scss
 * ----------------------------------------
 */
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-in-app {
  -webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
.fade-in-app-fast {
  -webkit-animation: fade-in 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
.fade-in-app-fast-delay {
  -webkit-animation: fade-in 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation-delay: 0.3s;
}

/**
 * ----------------------------------------
 * End animations
 * ----------------------------------------
 */
// custom
.nzmodal-custom .ant-modal-content {
  border-radius: 8px;
  font-family: Montserrat, Arial, Helvetica, sans-serif;
  .ant-btn {
    @include btn-small-tribuu;
    border: 2px solid rgba(0, 0, 0, 0.65);
    color: rgba(0, 0, 0, 0.65);
    padding: 0 16px;
  }
  .ant-btn.ant-btn-primary {
    background: var(--tribu-color-primary);
    border: 2px solid var(--tribu-color-primary);
    color: white;
  }
}

label.required::after {
  content: '*';
  color: var(--tribu-color-danger);
  margin-left: 2px;
}

label,
div {
  .required-reverse::before {
    content: '*';
    color: var(--tribu-color-danger);
    margin-right: 2px;
  }
}

// forms invalid
.ng-invalid._invalid,
._invalid > .ant-upload {
  border: 2px solid theme('colors.red.400');
}

nz-upload._invalid {
  svg-icon {
    color: theme('colors.red.400') !important;
  }
}

tui-radio {
  margin-bottom: 0 !important;
}

.multiSelectSearch .t-arrow,
.comboSearch .t-icon {
  display: none !important;
}

.pressable {
  @apply cursor-pointer transition-all duration-75 ease-in-out;
  &:active {
    @apply scale-95;
  }
}

.slide-in-right {
  -webkit-animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.slide-in-right-delay-500ms {
  -webkit-animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation-delay: 500ms;
}

@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-in-left {
  -webkit-animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@-webkit-keyframes slide-in-up {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-up {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

.slide-in-up {
  -webkit-animation: slide-in-up 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-up 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

body:has(> .shepherd-modal-is-visible) {
  overflow: hidden !important;
}
