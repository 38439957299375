// @import '@taiga-ui/core/styles/taiga-ui-fonts.less';
// @import '@taiga-ui/styles/taiga-ui-global.less';
// -------- override less variables -----------
@primary-color: #7473c0;
@heading-color: inherit;

.tui-slider-white {
  --tui-primary: var(--tribu-color-light);
  --tui-primary-hover: var(--tui-support-07);
  --tui-primary-active: var(--tui-support-08);
}

:root {
  --tui-secondary: #7473c0;
  --tui-secondary-hover: #5c5ba0;
  --tui-link: #fff;
  --tui-link-hover: #fff;
  --tui-base-03: #d9d9d9;
}
