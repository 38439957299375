/*==========  MontSerrrat  ==========*/

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat-VariableFont_wght.ttf') format('truetype');
  font-weight: 100 900;
}

/*==========  Forest Line  ==========*/
@font-face {
  font-family: 'Forest Line';
  src: url('../assets/fonts/forest_line_light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Forest Line';
  src: url('../assets/fonts/forest_line.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Forest Line';
  src: url('../assets/fonts/forest_line_bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
